import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import 'vue-swipe-actions/dist/vue-swipe-actions.css';
import { Debounce } from 'vue-debounce-decorator';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqsListModule from '@/store/modules/tasqsListModule';
import assetsModule from '@/store/modules/assetsModule';
import { getComponent, sleep } from '@/utils/helpers';
import {
  TASQS_LISTS,
  SHOW_ALERT,
  SHOW_ENDPOINT_RESPONSE_ALERT,
  VERTICAL_LIST_SORT_DETAULT_VALUE,
  VERTICAL_LIST_SORT_PRIORITY_DESC,
  VERTICAL_LIST_DEFAULT_LEVEL,
} from '@/lib/constants';
import Multiselect from 'vue-multiselect';
import { mixins } from 'vue-class-component';
import workflowModule from '@/store/modules/workflowModule';
import scheduleModule from '@/store/modules/scheduleModule';
import metaDataModule from '@/store/modules/metaDataModule';
import userProfileModule from '@/store/modules/userProfileModule';

import VerticalListFiltersAndSort from '@/components/tasqsCommon/VerticalListFiltersAndSort.vue';

import * as JsSearch from 'js-search';
import GenericMixin from '@/lib/mixins/GenericMixin';
import isOnline from 'is-online';

@Component({
  components: {
    TasqItem: () => getComponent('tasqs/TasqItem'),
    WorkTicketItem: () => getComponent('workTickets/WorkTicketItem'),
    PadItem: () => getComponent('tasqs/PadItem'),
    WellItem: () => getComponent('tasqs/WellItem'),
    TasqSubmittedSection: () => getComponent('tasqs/TasqSubmittedSection'),
    SpinnerLoader: () => getComponent('loaders/SpinnerLoader'),
    Multiselect,
    VerticalListFiltersAndSort,
  },
})
export default class TasqListMixin extends mixins(GenericMixin) {
  filterOpen = false;
  sortPopupOpen = false;
  showFiltersPopup = false;
  morePaginatedData = false;
  dataLoading = false;
  isLoadingDetails = false;
  selectedSortType = VERTICAL_LIST_SORT_DETAULT_VALUE;
  totalTasqCount = 0;
  waitingOnOptions: any = [];
  isOnlineApp = navigator.onLine;
  completedVisible = false;
  requestedMinDate: any = null;
  requestedMaxDate: any = null;
  localListLevel: string = VERTICAL_LIST_DEFAULT_LEVEL;
  maxNumCycleMax = 7;
  maxNumDefermentMax = 7;
  textSearchValueInput = '';
  activeList = 'ALL';
  filteredTasqResults: any = [];
  showSearch = false;
  registerScrollEvent = false;
  registerChangeEvent = false;
  currentTasqLimit = 20;
  currentCompletedLimit = 20;
  listViewTypeOptions = [
    { text: 'All Tasqs', value: 'Well' },
    { text: 'All Wells', value: 'WellView' },
    { text: 'All Pads', value: 'Pad' },
    { text: 'Work Tickets', value: 'WorkTicket' },
  ];
  listViewTypeOptionsMobile = [{ text: 'Tasqs', value: 'Well' }];
  filterBeingEdited = {};
  pageNameToLevelMapping: any = {
    'Tasqs': 'Well',
    'Wells': 'WellView',
    'Pads': 'Pad',
    'WorkTickets': 'WorkTicket',
  }

  async mounted() {
    if (!this.isMobileView) {
      tasqsListModule.resetPagination();
    }
    tasqsListModule.setIsEditing(false);
    tasqsListModule.setActivePage('Tasq');
    this.isLoadingDetails = true;
    await workflowModule.getWellStatuses()
  }

  get activeFilterFieldsCount() {
    let count = 0
    const settings = this.activeFilter.settings
    for (const property in settings) {
      if (property === 'selectedSortType') {
        continue
      }
      const value = settings[property]
      if (value && (value.constructor !== Array || (value.constructor === Array && value.length))) {
        count++
      }
    }
    return count
  }

  get activeFilter() {
    return tasqsListModule.activeFilterByPage(this.$route.name ?? '');
  }

  get mainSelectOptions() {
    let options: any = []
    if (this.isMobileView) {
      options = this.listViewTypeOptionsMobile
    } else {
      options = this.listViewTypeOptions.filter(
        option => option.value === this.tasqListLevel
      )
    }
    if (this.user.customFilters && this.user.customFilters.length) {
      let customfilters = this.user.customFilters.map(
        customFilter => ({ ...customFilter, text: customFilter.name, value: customFilter.id })
      )
      customfilters = customfilters.filter(
        customfilter => customfilter.level === this.tasqListLevel
      )
      options = [...options, ...customfilters]
    }
    return options
  }

  get selectedListViewText() {
    return this.listViewTypeOptions.find((l) => l.value === this.tasqListLevel)?.text;
  }

  get isWorkTicketView() {
    return this.tasqListLevel === 'WorkTicket';
  }

  get isPadView() {
    return this.tasqListLevel === 'Pad';
  }


  get isTasqView(){
    return this.tasqListLevel === 'Well'
  }

  get user() {
    return workflowModule.user;
  }

  get textSearchValue() {
    return tasqsListModule.textSearchValue;
  }

  get isMobileView() {
    return tasqsListModule.isMobileView;
  }

  get isEditing() {
    return tasqsListModule.isEditing;
  }

  get submittedTasqForms() {
    return tasqsListModule.submittedTasqs
      .map((tasq) => tasq.formsResponse.map((form) => ({ wellName: tasq.wellName, form })))
      .flat();
  }

  get pendingTasqForms() {
    return tasqsListModule.pendingTasqs
      .map((tasq) => tasq.formsResponse.map((form) => ({ wellName: tasq.wellName, form })))
      .flat();
  }

  get isLoadingTasqs() {
    return tasqsListModule.isLoadingTasqs;
  }

  get isLoadingPaginatedTasqs() {
    return tasqsListModule.isLoadingPaginatedTasqs;
  }

  get isLoadingSearchTasqs() {
    return tasqsListModule.isLoadingSearchTasqs;
  }

  get tasqListLevel() {
    return tasqsListModule.tasqListLevel;
  }

  get listOptions() {
    return TASQS_LISTS;
  }

  get moreTotalTasqs() {
    return tasqsListModule.todaysTasqLength;
  }

  get tasqs() {
    return tasqsListModule.tasqs();
  }

  get completedTasqs() {
    return tasqsListModule.completedTasqList;
  }


  get isDataLoading(){
    return this.isLoadingTasqs || this.isLoadingDetails || this.isLoadingPaginatedTasqs
  }

  get actionTodayTasqs() {
    return tasqsListModule.submittedTasqs;
  }

  get allTasqs() {
    let allTasqs = this.tasqs;
    if (!this.isMobileView && this.textSearchValue.length && this.tasqListLevel !== 'WorkTicket') {
      allTasqs = [...allTasqs, ...this.completedTasqs];
    }
    return allTasqs
  }

  get producingWells(): TasqJob[] {
    return tasqsListModule.producingWells;
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  get paginationHeight() {
    return tasqsListModule.infiniteScrollHeight;
  }

  get wellTasqs(): TasqJob[] {
    return tasqsListModule.additionalPadNestedTasqs;
  }

  get filteredTasqs() {
    let results = this.filteredTasqResults;
    if (this.completedVisible) {
      results = this.completedTasqs;
    }

    if (!results.length) {
      return [];
    }

    if (this.textSearchValue && this.textSearchValue.length) {
      if (this.tasqListLevel === 'Well' || this.tasqListLevel === 'WellView' || this.tasqListLevel === 'WorkTicket') {
        const waitingOnTasqs: any = results.filter((t) => t.snoozed);
        results = results.filter((t) => !t.snoozed);

        // @ts-ignore
        results = results.concat(waitingOnTasqs);
        const search = new JsSearch.Search('id');
        search.addDocuments(results);
        search.addIndex('wellName');
        results = search.search(this.textSearchValue);
      } else {
        const search = new JsSearch.Search('id');
        search.addDocuments(results);
        search.addIndex('padName');
        results = search.search(this.textSearchValue);
      }
    }

    this.totalTasqCount = this.countTotalTasqs(results)

    // @ts-ignore
    results = VerticalListFiltersAndSort.options.methods.sortTasqs(results, this.tasqListLevel, this.selectedSortType);

    if (results.length <= this.currentTasqLimit) {
      this.morePaginatedData = false;
    } else {
      this.morePaginatedData = true;
    }

    if (this.tasqListLevel === 'WorkTicket') {
      tasqsListModule.setFilteredWorkTickets(results);
    }

    return results.filter((tasq, index) => index < this.currentTasqLimit);
  }

  countTotalTasqs (records) {
    let count  = 0
    if (this.tasqListLevel === 'Pad') {
      records.forEach((t) => {
        count += this.wellTasqs.filter(
          (tasq) => tasq.predictionType !== 'Producing' && tasq.padName === t.padName
        ).length;
      });
    } else {
      count = records.filter((tasq) => tasq.predictionType !== 'Producing').length;
    }
    return count
  }

  resetListRecordsLimit (level: string = '') {
    if (!level) {
      level = this.tasqListLevel
    }
    if (level === 'WellView') {
      this.currentTasqLimit = 10;
    } else {
      this.currentTasqLimit = 20;
    }
  }

  handleFilterIconClick (event, filter) {
    if (filter) {
      this.filterBeingEdited = {
        "id": filter.id,
        "name": filter.name,
        "settings": filter.settings
      }
    } else if (Object.keys(this.activeFilter.settings).length) {
      this.filterBeingEdited = JSON.parse(JSON.stringify(this.activeFilter))
      delete this.filterBeingEdited['id']
    } else {
      this.filterBeingEdited = {}
    }
    this.showFiltersPopup = true
  }

  async handleFilterDelete (filter) {
    await userProfileModule.deleteCustomFilter(filter.id)
    tasqsListModule.resetActiveFilter()
    this.localListLevel = this.tasqListLevel
    this.applyFiltering()
  }

  handleRecordsFiltered (results, sortBy) {
    this.resetListRecordsLimit()
    this.filteredTasqResults = results
    this.selectedSortType = sortBy
    if (this.activeFilter.id) {
      this.localListLevel = this.activeFilter.id
    } else {
      this.localListLevel = this.tasqListLevel
    }
  }

  handleCustomFilter (customFilterId) {
    let activeFilter = this.user.customFilters.find(customFilter => customFilter.id === customFilterId);
    tasqsListModule.setActiveFilter(activeFilter);
    this.applyFiltering();
  }

  updateFilterSelectedValue(payload) {
    this[payload.title] = payload.values;
  }

  addTasq(type) {
    if (type === 'addTasq') {
      this.$emit('add-tasq');
    } else {
      this.$emit('add-work-ticket');
    }
  }

  editTasqs() {
    this.$emit('edit-tasq');
  }

  async reassignTasq(tasq) {
    await this.fetchTasq(tasq);
    this.$emit('reassign-tasq');
  }

  async snoozeTasq(tasq) {
    await this.fetchTasq(tasq);
    this.$emit('snooze-tasq');
  }

  async waitingOnTasq(tasq) {
    await this.fetchTasq(tasq);
    this.$emit('waiting-on-tasq');
  }

  async rejectTasq(tasq) {
    await this.fetchTasq(tasq);
    this.$emit('reject-tasq');
  }

  resetAllFilters () {
    tasqsListModule.resetActiveFilter()
    this.localListLevel = this.tasqListLevel
    this.selectListLevel(this.tasqListLevel)
  }

  handleMainSelectOptionChange (level) {
    let customFilterId = ''
    const listViewTypeOptionsValues = this.listViewTypeOptions.map(function(item) {
      return item['value'];
    });
    if (!listViewTypeOptionsValues.includes(level)) {
      customFilterId = level
      this.handleCustomFilter(level)
    } else {
      if (this.activeFilter.id) {
        tasqsListModule.resetActiveFilter();
      }
      this.applyFiltering();
    }
    userProfileModule.updateDefaultFilter({pageName: this.$route.name, filterId: customFilterId});
  }

  async selectListLevel(level) {
    if (!this.isOnlineApp) {
      return
    }

    this.isLoadingDetails = true;
    if (level === 'WorkTicket') {
      this.selectedSortType = VERTICAL_LIST_SORT_PRIORITY_DESC;
      tasqsListModule.setActiveTasq('');
      this.filteredTasqResults = [];
      tasqsListModule.setTasqListLevel(level);
      await tasqsListModule.getWorkTicketTasqs();
    } else {
      this.selectedSortType = VERTICAL_LIST_SORT_DETAULT_VALUE;
      tasqsListModule.setActiveWorkTicket('');
      tasqsListModule.setActiveTasq('');
      await sleep(200);
      this.resetListRecordsLimit(level)
      const navigateFromTasqToWellView = this.tasqListLevel === 'Well' && level === 'WellView';
      const navigateFromWellToTasqView = level === 'Well' && this.tasqListLevel === 'WellView';
      tasqsListModule.setTasqListLevel(level);
      if (!(navigateFromTasqToWellView || navigateFromWellToTasqView)) {
        tasqsListModule.resetPagination();
        await this.changeTasqList();
      }
    }
    this.isLoadingDetails = false;
    await this.applyFiltering();
  }

  async changeTasqList(isCreated = false) {
    const list = this.listOptions.find((i) => i.val === this.activeList) as { text: string; val: string };

    if (!isCreated) {
      tasqsListModule.setActiveTasq('');
    }
    tasqsListModule.setIsEditing(false);
    tasqsListModule.setIsBatchResponding(false);
    tasqsListModule.setIsBatchReassign(false);
    tasqsListModule.resetCheckedTasqs();
    await tasqsListModule.getTasqs(list);
  }

  async applyFiltering(resetRecordsLimit = true) {
    if (resetRecordsLimit) {
      this.resetListRecordsLimit()
    }
    let activeFilter = this.activeFilter
    // @ts-ignore
    let results = VerticalListFiltersAndSort.options.methods.applyFilteringOnTasqs(
      this.allTasqs, this.tasqListLevel, activeFilter.settings
    )

    if (this.tasqListLevel === 'WellView') {
      const newResults: any = [];
      results.forEach((tasq) => {
        const index = newResults.findIndex((t) => t.wellName === tasq.wellName);
        if (index < 0) {
          let anotherTasq: any = tasq;
          const relatedTasq = this.tasqs.find((t) => t.wellName === tasq.wellName && t.predictionType === 'Off-Target');
          if (relatedTasq) {
            anotherTasq = relatedTasq;
          }
          newResults.push(anotherTasq);
        }
      });

      results = newResults
    }
    this.handleRecordsFiltered(results, activeFilter.settings.selectedSortType)
  }

  paginationHandler({ target: { scrollTop, clientHeight, scrollHeight } }) {
    if (Math.ceil(scrollTop + clientHeight + 150) >= scrollHeight && !this.registerScrollEvent) {
      this.registerScrollEvent = true;
      tasqsListModule.setInfiniteListScroll(scrollHeight);
      sleep(50).then(() => {
        this.registerScrollEvent = false;
        this.currentTasqLimit += 20;
      });
    }
  }

  async fetchTasq(tasq) {
    await tasqsListModule.setActiveTasqExplicit(tasq);
  }

  async prepareListView() {
    this.isOnlineApp = await isOnline();
    const listLevel = this.pageNameToLevelMapping[this.$route.name ?? 'Tasqs']
    if (listLevel) {
      tasqsListModule.setTasqListLevel(listLevel);
    }
    if (this.tasqListLevel === 'WorkTicket') {
      await this.prepareWorkTicketList({ fetchWorkTickets: true });
    } else {
      await  workflowModule.getWellStatuses()
      await this.prepareTasqList();
    }
  }

  async prepareTasqList() {
    
    if (!this.isMobileView || !this.tasqs) {
      const promiseList: any = [];
      promiseList.push(this.changeTasqList(true));
      // if (this.isMobileView) {
      //   promiseList.push(tasqsListModule.getSubmittedForms());
      // }

      promiseList.push(tasqsListModule.getSubmittedForms());
      this.loadCommonData(promiseList).then(async () => {
        await this.setWaitingOnOptions();
      })
    } else {
      await sleep(1500);
      this.isLoadingDetails = false;
      await this.applyFiltering();
    }
  }

  async prepareWorkTicketList({ fetchWorkTickets = false }) {
    this.selectedSortType = VERTICAL_LIST_SORT_PRIORITY_DESC;
    const promiseList: any = [];
    if (fetchWorkTickets) {
      tasqsListModule.setActiveTasq('');
      promiseList.push(tasqsListModule.getWorkTicketTasqs());
    }
    this.loadCommonData(promiseList).then(async () => {
      this.filteredTasqResults = [];
      await this.applyFiltering();

    })
  }

  loadCommonData (promiseList: any = null) {
    if (!promiseList) {
      promiseList = []
    }
    promiseList.push(workflowModule.listTeams({ useExisting: true }));
    promiseList.push(assetsModule.getEnabledPads());
    promiseList.push(scheduleModule.listAreas({ useExisting: true }));
    promiseList.push(scheduleModule.getEnabledRoutes());

    if (!this.isMobileView) {
      promiseList.push(this.setRequiredDatalistOptions());
    }

    return new Promise((resolve, reject) => {
      Promise.all(promiseList)
        .then(async () => {
          this.isLoadingDetails = false;
          this.localListLevel = this.tasqListLevel;
          resolve(null)
        })
        .catch((err) => {
          console.error(err);
          this.$eventBus.$emit(SHOW_ENDPOINT_RESPONSE_ALERT, {
            text: 'It is not possible to show some list data at this moment, please try later',
            error: true,
          });
          this.isLoadingDetails = false;
          reject(err);
        })
    })
  }

  async setWaitingOnOptions() {
    if (!(metaDataModule.requiredDatalist && metaDataModule.requiredDatalist.length)) {
      await metaDataModule.getDatalist();
    }
    const requiredDataList = metaDataModule.requiredDatalist;
    const formCategoriesItem = requiredDataList.find((list) => list.title === 'Waiting On');
    if (formCategoriesItem) {
      const formCategoriesPayload = await metaDataModule.getDatalist(formCategoriesItem.id);
      if (formCategoriesPayload && formCategoriesPayload.length && formCategoriesPayload[0].ListItems) {
        const waitingOnOptions = formCategoriesPayload[0].ListItems.filter((field) => field.Active).map(
          (item) => item.Name
        );
        this.waitingOnOptions = ['None'].concat(waitingOnOptions.sort());
      }
    }
  }

  @Debounce(1500)
  @Watch('textSearchValueInput')
  async updateFilterVal(data) {
    this.filteredTasqResults = [];
    this.resetListRecordsLimit()
    tasqsListModule.setTextSearchValue(data)
    this.applyFiltering(false);
  }

  @Watch('isLoadingTasqs')
  @Debounce(1000)
  async updateTasqsValue(value) {
    // @ts-ignore
    const scrollDivId = localStorage.getItem('ActiveTasqScroll');
    if (value === false) {
      this.applyFiltering(false);
      if (tasqsListModule.kanbanSelectedTasq) {
        tasqsListModule.setKanbanSelectedTasq('');
      }
    }
    if (scrollDivId && scrollDivId.length) {
      sleep(2000).then(() => {
        const activeTasqDiv = document.getElementById(scrollDivId)!;
        if (activeTasqDiv) {
          activeTasqDiv.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'nearest' });
          localStorage.setItem('ActiveTasqScroll', '');
        }
      });
    }
  }

  @Watch('isLoadingPaginatedTasqs')
  @Debounce(1000)
  async updateTasqsPaginatedValue(value) {
    if (value === false) {
      this.applyFiltering(false);
    }
  }

  @Debounce(1000)
  @Watch('producingWells')
  onProducingWellsUpdate(data) {
    if (data && data.length) {
      this.applyFiltering(false);
    }
  }

  @Watch('completedVisible')
  onCompletedWellsUpdate() {
    this.currentTasqLimit = 20;
  }

  @Watch('$route.name')
  async onTasqRouteIdChange(routeName: any) {
    this.localListLevel = this.pageNameToLevelMapping[routeName]
    this.selectListLevel(this.localListLevel);
  }
}
