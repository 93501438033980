import { render, staticRenderFns } from "./TasqsVerticalList.vue?vue&type=template&id=42e80996&scoped=true&"
import script from "./TasqsVerticalList.vue?vue&type=script&lang=ts&"
export * from "./TasqsVerticalList.vue?vue&type=script&lang=ts&"
import style0 from "./TasqsVerticalList.vue?vue&type=style&index=0&id=42e80996&prod&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42e80996",
  null
  
)

export default component.exports